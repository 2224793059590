<template>
  <div class="widgetContainer widgetContainer--scrollable contactDetails">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('contact_Details_Title') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body" v-if="getSelectedContactData">
      <div class="contactDetails__details">
        <h4 class="header-2 initialCaps">
          {{ getSelectedContactData.name }}
        </h4>

        <ul class="listWithInfo border-1">
          <li>
            <span class="label">{{ $t('phone') }}</span>
            <span class="value">{{ phone }}</span>
          </li>
          <li>
            <span class="label">{{ $t('email') }}</span>
            <span class="value">
              {{
                getSelectedContactData.email
                  ? getSelectedContactData.email
                  : 'NA'
              }}
            </span>
          </li>
          <li v-if="getSelectedContactData.check && getSelectedContactData.check.address">
            <span class="label">{{ $t('Address') }}</span>
            <span class="value">{{ address }}</span>
          </li>
        </ul>

        <h4 class="header-2">
          {{ $t('actions') }}
        </h4>

        <div class="listWithActionsv2 delete-contact">
          <ul>
            <li @click="pay">
              <div class="details">
                <p class="label">
                  {{ $t('contact_make_payment_title') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
            <li @click="payHistory">
              <div class="details">
                <p class="label">
                  {{ $t('contact_paymenthistory_title') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
            <li @click="editContact">
              <div class="details">
                <p class="label">
                  {{ $t('contact_details_Edit_row') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
            <li @click="toggleDialogToDeleteContact(true)">
              <div class="details">
                <p class="label">
                  {{ $t('contact_row_DeleteContact') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Delete contact confirmation -->
    <el-dialog
      title="Delete Contact"
      :visible="dialogToDeleteContact"
      :append-to-body="true"
      width="400px"
      @close="toggleDialogToDeleteContact(false);"
      :show-close="false"
      custom-class="dialog-delete-contact fdsa"
      center>
      <span class="dialog-delete-contact__title">
        Are you sure you want to delete the contact?
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn-cancel" @click.native="toggleDialogToDeleteContact(false)">{{ $t('cancel') }}</el-button>
        <el-button class="brand" type="primary" @click.native="deleteContact">
          Delete
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { accountType } from '../enums';
import { formatPhoneNumber } from '@/utils/general';

export default {
  name: 'ContactDetails',
  data() {
    return {
      lat: null,
      lng: null,
      activeMenus: [],
      dialogToDeleteContact: false
    };
  },
  computed: {
    ...mapGetters('contact', ['getSelectedContactData']),
    accountType() {
      return accountType[this.getSelectedContactData.ach.accountType];
    },
    phone() {
      if (this.getSelectedContactData.phone) {
        return formatPhoneNumber(this.getSelectedContactData.phone);
      }
      return 'NA';
    },
    address() {
      const address = this.getSelectedContactData.check.address;
      return (
        address.line1 +
        ',' +
        address.city +
        ',' +
        address.state
      );
    },
  },
  created() {
    if (!this.getSelectedContactData) {
      this.drawerClose();
    }
  },
  methods: {
    ...mapMutations('contact', ['updateCreateContactData']),
    ...mapActions('contact', ['archiveContact']),
    ...mapMutations('transaction', ['updateFilters']),
    toggleDialogToDeleteContact(param) {
      this.dialogToDeleteContact = param;
    },
    editContact() {
      const { id, accountId, address, ach, intrabank, name, email, phone } =
        this.getSelectedContactData;
      this.updateCreateContactData({
        id,
        accountId,
        address,
        ach,
        intrabank,
        name,
        email,
        phone
      });
      setTimeout(() => {
        this.drawerPush('edit-contact');
        //this.$router.push('/contact/detailForm');
      }, 0);
    },
    goToContacts() {
      this.$router.push('/contact/list');
    },
    deleteContact() {
      const loader = this.showLoader();
      this.archiveContact(this.getSelectedContactData.id)
        .then(() => {
          setTimeout(() => {
            this.$emit('fetchContacts');
            this.drawerClose();
            // this.$router.push('/contact/list');
          }, 0);
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          this.dialogToDeleteContact = false;
          loader.close();
        });
    },
    pay() {
      this.drawerPush('paymentType');
      // this.$router.push('/pay/paymentType');
    },
    payHistory() {
      this.updateFilters({
        contactId: this.getSelectedContactData.id,
        cardId: null
      });
      this.$router.push('/transaction/list');
    }
  }
};
</script>

<style lang="scss">
.contactDetails {
  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    .back {
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon-arrow-left {
        font-size: 18px;
      }

      p {
        color: #3c3c43;
        font-size: 17px;
        padding-left: 6px;
      }
    }
    .edit {
      color: var(--branding);
      font-size: 17px;
      cursor: pointer;
    }
    .listWithInfo {
      border: 1px solid #EDEFF1;
    }
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
  }

  &__details {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 7px;

      .edit {
        font-size: 14px;
        line-height: 16px;
        color: var(--grey-1);
        cursor: pointer;
      }
    }
  }

  .remove {
    font-size: 17px;
    background-color: #0e0e0f;
    color: #fff;
    margin-top: 30px;
    width: 100%;
    padding: 14px;
    line-height: 21px;
  }
}

.dialog-delete-contact {
  .el-dialog {
    &__title {
      font-weight: 700;
    }
    
    &__body {
      text-align: center;
    }
    
    .el-button {
      height: 38px;
      
      + .el-button {
        margin-left: 24px;
        background-color: var(--branding);
        color: #fff;
      }
    }
  }
}

.delete-contact {
  li {
    height: 64px;
  }
}
</style>
